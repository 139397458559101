import React, { useEffect } from "react";
import { Row, Col, Tab, Tabs, Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FaDownload, FaHome, FaStar } from "react-icons/fa";
import DatePicker from "react-datepicker";

import { getCalendarKPIperiod } from "../../services/calendar";
import YearlyWeekPicker from "../week-picker/YearlyWeekPicker";
import { CompareResponsiveBarChart } from "./CompareResponsiveBarChart";
import { CompareResponsivePieChart } from "./CompareResponsivePieChart";
import { CompareResponsiveLineChart } from "./CompareResponsiveLineChart";
import { platforms as defaultPlatforms } from "../../utilities/platforms";
import SourceFilter from "../filter/SourceFilter";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import XLSX from "xlsx";

import "./Compare.css";
import "react-datepicker/dist/react-datepicker.css";

const buttonMap = [
  ["Number of rows"],
  ["Number of positions"],
  ["Number of unique titles"],
  ["Content type percentage"],
  ["Unique titles ratio"],
];

const specialButtonMap = [
  ["Unique titles"],
  ["Unique own titles"],
  [
    <>
      Unique{" "}
      <div style={{ fontWeight: "bold", display: "inline-block" }}>AMC+</div>{" "}
      Title
    </>,
  ],
  ["Total priviledged positions"],
  ["Unique movies"],
  ["Unique series"],
  ["Transactional ratio"],
];

const index2key = {
  1: "num_rows",
  2: "num_position",
  3: "num_unique",
  4: ["num_unique_serie", "num_unique_movie", "num_unique"],
  5: "num_unique_ratio",

  6: "pp_num_unique",
  7: "pp_num_unique_own_ratio",
  8: "pp_num_unique_amc_ratio",
  9: "pp_total_priviledged_positions",
  10: "pp_num_unique_movie",
  11: "pp_num_unique_serie",
  12: "pp_num_transactional_ratio",
};

const index2Header = {
  1: "Rows",
  2: "Positions",
  3: "Unique titles",
  4: "Content type ratios",
  5: "Unique titles by type",

  6: "Privileged positions' uniques",
  7: "Own platform productions on privileged positions",
  8: (
    <>
      <div style={{ fontWeight: "bolder", display: "inline-block" }}>AMC+</div>{" "}
      on privileged positions
    </>
  ),
  9: "Privileged positions",
  10: "Unique movies",
  11: "Unique series",
  12: "Transactional ratio",
};

const index2Subheader = {
  1: "Number of rows on main page",
  2: "Number of positions on main page",
  3: "Number of unique titles on main page",
  4: "Percentage of number of unique titles on main page / Number of positions on main page",
  5: "Unique titles by type (series, movies or other) / Total unique titles on main page",

  6: "Number of unique titles on privileged positions",
  7: "Ratio of own production unique titles / number of unique titles on privileged positions",
  8: (
    <>
      Ratio of{" "}
      <div style={{ fontWeight: "bolder", display: "inline-block" }}> AMC+</div>{" "}
      unique titles / number of unique titles on privileged positions
    </>
  ),
  9: "Slider on main page + 1 block of the 3 first rows on main page",
  10: "Number of unique movies on privileged positions",
  11: "Number of unique series on privileged positions",
  12: " % of transactional content on main page / Total content on main page",
};

const index2format = {
  5: { valueFormat: " >-.2f" },
  7: { valueFormat: " >-.2f" },
  8: { valueFormat: " >-.2f" },
  12: { valueFormat: " >-.2f" },
};

function formatKey(key, type) {
  switch (type) {
    case 0:
      return [
        "day",
        new Date(key).getDate() + "/" + (new Date(key).getMonth() + 1),
      ];
    case 1:
      return [
        "week",
        "week " + key.substring(4, 6) + "-" + key.substring(2, 4),
      ];
    case 2:
      return [
        "month",
        "month " + key.substring(4, 6) + "-" + key.substring(2, 4),
      ];
    case 3:
      return ["year", "year " + key];
    default:
      console.log("error, invalid type ", type);
  }
}

function adaptToPieTypes(data, platform) {
  const base = {
    num_unique_serie: 0,
    num_unique_movie: 0,
    num_unique: 0,
  };
  const trad = {
    num_unique_serie: "series",
    num_unique_movie: "movies",
    num_unique: "other",
  };
  let aux = 0;
  data.forEach((k) => {
    k.forEach((e) => {
      const keyRow = Object.keys(e[platform])[0];
      if (keyRow !== "num_unique") {
        base[keyRow] += Number.parseFloat(e[platform][keyRow]);
        aux += Number.parseFloat(e[platform][keyRow]);
      } else {
        base[keyRow] += Number.parseFloat(e[platform][keyRow]) - aux;
        aux = 0;
      }
    });
  });
  let total = base.num_unique_serie + base.num_unique_movie + base.num_unique;
  if (total === 0) {
    base.num_unique = 100;
    total = 100;
  }

  Object.keys(base).forEach((k) => (base[k] = (base[k] / total) * 100));

  return Object.keys(base).map((k) => {
    return {
      id: `${platform} ${trad[k]}`,
      label: `${platform} ${trad[k]}`,
      value: base[k],
    };
  });
}

function adaptToPie(data, key, platforms) {
  const sm = data.reduce(
    (dict, d) => {
      dict[platforms[0]] += Number.parseFloat(d[platforms[0]]);
      dict[platforms[1]] += Number.parseFloat(d[platforms[1]]);
      return dict;
    },
    { [platforms[0]]: 0, [platforms[1]]: 0 }
  );
  sm[platforms[0]] = sm[platforms[0]] / data.length;
  sm[platforms[1]] = sm[platforms[1]] / data.length;
  return [
    {
      id: key[platforms[0]],
      label: platforms[0],
      value: sm[platforms[0]],
      color: "red",
    },
  ];
}

function adaptToLine(data, type, platforms) {
  let lineResult = Object.values(
    data.reduce((p, d) => {
      Object.entries(d).forEach(([key, value]) => {
        if (
          key !== "day" &&
          key !== "week" &&
          key !== "month" &&
          key !== "year"
        ) {
          if (!p[key]) {
            p[key] = { id: key, data: [] };
          }
          if (d.day) {
            p[key].data.push({ x: d.day, y: value });
          } else if (d.week) {
            p[key].data.push({ x: d.week, y: value });
          } else if (d.month) {
            p[key].data.push({ x: d.month, y: value });
          } else {
            p[key].data.push({ x: d.year, y: value });
          }
        }
      });
      return p;
    }, {})
  );
  return lineResult;
}

function adaptToBinary(data, type, platform) {
  const o = { id: "amc", data: [] };
  let lineResult = [];
  lineResult = Object.values(
    Object.values(
      data.reduce((p, d) => {
        Object.entries(d).forEach(([key, value]) => {
          if (
            key !== "day" &&
            key !== "week" &&
            key !== "month" &&
            key !== "year" &&
            key === platform
          ) {
            if (!p[key]) {
              p[key] = { id: key, data: [] };
            }
            if (d.day) {
              p[key].data.push({ x: d.day, y: 100 - Number.parseFloat(value) });
              o.data.push({ x: d.day, y: Number.parseFloat(value) });
            } else if (d.week) {
              p[key].data.push({ x: d.week, y: Number.parseFloat(value) });
              o.data.push({ x: d.week, y: Number.parseFloat(value) });
            } else if (d.month) {
              p[key].data.push({ x: d.month, y: Number.parseFloat(value) });
              o.data.push({ x: d.month, y: Number.parseFloat(value) });
            } else {
              p[key].data.push({ x: d.year, y: Number.parseFloat(value) });
              o.data.push({ x: d.year, y: Number.parseFloat(value) });
            }
          }
        });
        return p;
      }, {})
    )
  );
  lineResult.push(o);
  return lineResult;
}

function mapGraphData(
  data,
  index,
  type,
  platforms = ["netflix", "amazon"],
  platformId
) {
  const rowKey = index2key[index];
  const res = Array.isArray(rowKey)
    ? Object.keys(data.datos).map((k) => {
        const format = formatKey(k, type);
        const final = rowKey.map((k2) => {
          let names = defaultPlatforms.map((item) => item.name);
          return {
            [format[0]]: format[1],
            ...names.reduce((d, p) => {
              d[p] = d[p] ?? {};
              d[p][k2] = data?.datos?.[k]?.[platformId.platform]?.[k2] ?? 0;
              return d;
            }, {}),
          };
        });

        return final;
      })
    : Object.keys(data.datos).map((k) => {
        const format = formatKey(k, type);
        if (index !== 8) {
          return {
            [format[0]]: format[1],
            ...platforms.reduce((d, p) => {
              d[p] = data.datos?.[k]?.[p]?.[rowKey] ?? 0;
              return d;
            }, {}),
          };
        } else {
          let names = defaultPlatforms.map((item) => item.name);
          return {
            [format[0]]: format[1],
            ...names.reduce((d, p) => {
              d[p] = data.datos?.[k]?.[platformId.platform]?.[rowKey] ?? 0;
              return d;
            }, {}),
          };
        }
      });
  switch (index) {
    case 4:
      return adaptToPieTypes(res, platformId.platform);
    case 3:
    case 6:
    case 9:
      return adaptToLine(res, type, platforms);
    case 8:
      return adaptToBinary(res, type, platformId.platform);
    case 12:
      return res.map((e) => {
        e[platforms[0]] *= 100;
        e[platforms[1]] *= 100;
        return e;
      });
    case 9999:
      return adaptToPie(res, type, platforms);
    default:
      return res;
  }
}

export default function Compare({ month = Date.now(), data = {}, ...props }) {
  const [sourceFilter, setSourceFilter] = React.useState({
    netflix: true,
    amazon: true,
  });
  const platforms = Object.keys(sourceFilter)
    .filter((k) => sourceFilter[k])
    .map(String);
  const [platformId, setPlatformId] = React.useState({ platform: "amazon" });

  const [graphInfo, setGraphInfo] = React.useState({ datos: {} });
  const [dataIndex, setDataIndex] = React.useState(1);
  const [dateFilter, setDateFilter] = React.useState(() => {
    const begin = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const end = new Date(begin.getFullYear(), begin.getMonth() + 1, 2);
    return {
      current: 0,
      begin: begin,
      end: end,
    };
  });

  const [staticIndex, setStaticIndex] = React.useState(0);
  const [alert, setAlert] = React.useState(null);
  const graphData =
    dateFilter.begin && dateFilter.end
      ? mapGraphData(
          graphInfo,
          dataIndex,
          dateFilter.current,
          platforms,
          platformId
        ) ?? []
      : [];

  const getGraph = () => {
    switch (dataIndex) {
      case 4:
        return (
          <CompareResponsivePieChart
            data={graphData}
            platform={platformId.platform}
          />
        );
      case 3:
      case 6:
      case 9:
        return CompareResponsiveLineChart({
          data: graphData,
          platforms: platforms,
        });

      case 8:
        return (
          <CompareResponsiveLineChart
            data={graphData}
            platform={platformId.platform}
            key={platformId.platform}
          />
        );
      case 5:
      case 7:
      case 12:
        return CompareResponsiveBarChart(
          {
            data: graphData,
            platforms: platforms,
          },
          ["day", "week", "month", "year"][staticIndex],
          index2format[dataIndex] ?? {},
          "%"
        );
      default:
        return CompareResponsiveBarChart(
          {
            data: graphData,
            platforms: platforms,
          },
          ["day", "week", "month", "year"][staticIndex],
          index2format[dataIndex] ?? {}
        );
    }
  };

  const getData = async () => {
    if (dateFilter.begin > dateFilter.end) {
      setAlert("The begin date must be prior to the end date");
      return;
    }

    getCalendarKPIperiod(
      dateFilter.begin,
      dateFilter.end,
      ["DAY", "WEEK", "MONTH", "YEAR"][dateFilter.current]
    ).then((d) => {
      if (d && d.datos) {
        setStaticIndex(dateFilter.current);
        setGraphInfo(d);
      } else {
        setAlert("No results");
      }
    });
  };

  const setBegining = (d) => {
    if (Number.isNaN(new Date(d).getTime())) return;

    dateFilter.begin = d;
    if (dateFilter.end) {
      getData();
    } else {
      setDateFilter({ ...dateFilter });
    }
  };

  const setEnd = (d) => {
    if (Number.isNaN(new Date(d).getTime())) return;

    dateFilter.end = d;
    if (dateFilter.begin) {
      getData();
    } else {
      setDateFilter({ ...dateFilter });
    }
  };

  const adjustedIndex = (dex) => {
    return Object.keys(buttonMap).length + 1 + dex;
  };
  const handleDownload = () => {
    console.log("gga", graphData);
    var elementId = "graph";
    //Create the pdf file
    domtoimage
      .toJpeg(document.getElementById(elementId))
      .then(function (dataUrl) {
        var pdf = new jsPDF("l", "mm", [180, 300]);
        pdf.addImage(dataUrl, "JPEG", 0, 0, 300, 180);
        pdf.save(`${index2key[dataIndex]}.pdf`);
      });
    //Create the excel file
    let sheet;
    const book = XLSX.utils.book_new();
    if (
      dataIndex === 3 ||
      dataIndex === 6 ||
      dataIndex === 8 ||
      dataIndex === 9
    ) {
      sheet = XLSX.utils.json_to_sheet(graphData);
      graphData.forEach(({ id, data }) => {
        sheet = XLSX.utils.aoa_to_sheet([
          ["x", "y"],
          ...data.map((d) => [d.x, d.y]),
        ]);
        XLSX.utils.book_append_sheet(book, sheet, id);
      });
    } else {
      sheet = XLSX.utils.json_to_sheet(graphData);
      XLSX.utils.book_append_sheet(book, sheet, "Sheet1");
    }

    XLSX.writeFile(book, `${index2key[dataIndex]}.xlsx`);
  };
  const getCalendars = () => {
    switch (dateFilter.current) {
      case 0:
        return (
          <>
            <Col xs={6}>
              <Row className="date-head-picker">Start</Row>
              <DatePicker
                className="custom-input-date"
                dateFormat="dd/MM/yyyy"
                selected={dateFilter?.begin}
                onChange={(e) => setBegining(e)}
              />
            </Col>
            <Col xs={6}>
              <Row className="date-head-picker">End</Row>
              <DatePicker
                className="custom-input-date"
                dateFormat="dd/MM/yyyy"
                selected={dateFilter?.end}
                onChange={(e) => setEnd(e)}
              />
            </Col>
          </>
        );
      case 1:
        return (
          <>
            <Col xxl={6} sm={6}>
              <Row className="date-head-picker">Start</Row>
              <YearlyWeekPicker
                onChange={(e) => {
                  setBegining(new Date(e));
                }}
                start={dateFilter.begin}
                accordionProps={{ style: { minWidth: "170px" } }}
                style={{ fontSize: "12px" }}
                mode="sunday"
              />
            </Col>
            <Col xxl={6} sm={6}>
              <Row className="date-head-picker">End</Row>
              <YearlyWeekPicker
                onChange={(e) => {
                  setEnd(new Date(e));
                }}
                start={dateFilter.end}
                accordionProps={{ style: { minWidth: "170px" } }}
                style={{ fontSize: "12px" }}
                mode="sunday"
              />
            </Col>
          </>
        );
      case 2:
        return (
          <>
            <Col md={6}>
              <Row className="date-head-picker">Start</Row>
              <DatePicker
                className="custom-input-date"
                selected={dateFilter.begin}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                onChange={(d) => {
                  setBegining(d);
                }}
              />
            </Col>
            <Col md={6}>
              <Row className="date-head-picker">End</Row>
              <DatePicker
                className="custom-input-date"
                selected={dateFilter.end}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                onChange={(d) => {
                  setEnd(new Date(d.getFullYear(), d.getMonth() + 1, 0));
                }}
              />
            </Col>
          </>
        );
      default:
        return (
          <>
            <Col md={6}>
              <Row className="date-head-picker">Start</Row>
              <DatePicker
                className="custom-input-date"
                selected={dateFilter.begin - 1}
                dateFormat="yyyy"
                showYearPicker
                onChange={(d) => {
                  setBegining(d);
                }}
              />
            </Col>
            <Col md={6}>
              <Row className="date-head-picker">End</Row>
              <DatePicker
                className="custom-input-date"
                selected={dateFilter.end}
                showYearPicker
                dateFormat="yyyy"
                onChange={(d) => {
                  setEnd(new Date(d.getFullYear(), 11, 31));
                }}
              />
            </Col>
          </>
        );
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [dateFilter, platformId]);
  return (
    <Row className="mt-2 justify-content-around">
      <Col xxl={4} md={6} sm={12} className="pb-2">
        <Alert
          variant="danger"
          dismissible={true}
          show={alert !== null}
          onClose={() => {
            setAlert(null);
          }}
          transition={true}
          className="mx-5"
        >
          {alert ? alert : ""}
        </Alert>
        <Row className="tab-bubble">
          <Row className="date-select-filter">
            <Col md={"auto"} xs={"auto"}>
              <label
                className={`filter-date ${
                  dateFilter.current === 0 ? "active" : ""
                }`}
              >
                Day
                <Form.Check
                  onClick={() => {
                    if (dateFilter.current !== 0)
                      setDateFilter({
                        ...dateFilter,
                        current: 0,
                      });
                  }}
                  className="d-none"
                  type={"radio"}
                  name={"groupingSelect"}
                />
              </label>
            </Col>
            <Col md={"auto"} xs={"auto"}>
              <label
                className={`filter-date ${
                  dateFilter.current === 1 ? "active" : ""
                }`}
              >
                Week
                <Form.Check
                  onClick={() => {
                    if (dateFilter.current !== 1)
                      setDateFilter({
                        ...dateFilter,
                        current: 1,
                      });
                  }}
                  className="d-none"
                  type={"radio"}
                  name={"groupingSelect"}
                />
              </label>
            </Col>
            <Col md={"auto"} xs={"auto"}>
              <label
                className={`filter-date ${
                  dateFilter.current === 2 ? "active" : ""
                }`}
              >
                Month
                <Form.Check
                  onClick={() => {
                    if (dateFilter.current !== 2)
                      setDateFilter({
                        ...dateFilter,
                        current: 2,
                      });
                  }}
                  className="d-none"
                  type={"radio"}
                  name={"groupingSelect"}
                />
              </label>
            </Col>
            <Col md={"auto"} xs={"auto"}>
              <label
                className={`filter-date ${
                  dateFilter.current === 3 ? "active" : ""
                }`}
              >
                Year
                <Form.Check
                  onClick={() => {
                    if (dateFilter.current !== 3)
                      setDateFilter({
                        ...dateFilter,
                        current: 3,
                      });
                  }}
                  className="d-none"
                  type={"radio"}
                  name={"groupingSelect"}
                />
              </label>
            </Col>
            <Row className="calendar-container my-4 mx-2">{getCalendars()}</Row>
            {dataIndex === 4 || dataIndex === 8 ? (
              <Row className="logos-vs text-center ">
                <Col xs={6}>
                  <Form.Select
                    value={platformId.platform}
                    onChange={(e) => {
                      setPlatformId((p) => ({
                        ...p,
                        platform: e.target.value,
                      }));
                    }}
                  >
                    <option disabled value={""}>
                      Select platform
                    </option>
                    {defaultPlatforms.map((p) => (
                      <option
                        disabled={p.name === platformId.platform}
                        value={p.name}
                        key={p.name}
                      >
                        {p.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-start mt-3">
                <Col style={{ maxWidth: "200px" }}>
                  <SourceFilter
                    filter={sourceFilter}
                    onChange={(f) => setSourceFilter(f)}
                  />
                </Col>
              </Row>
            )}
          </Row>
          <Row>
            <Col className="h3 text-black my-3">KPI's</Col>
          </Row>
          <Row className={`${!platforms ? "disabled-controls" : ""}`}>
            <Col className="tabs-comparative" md={"auto"}>
              <Tabs defaultActiveKey="platform" fill>
                <Tab
                  eventKey="platform"
                  title={
                    <span>
                      <FaHome size="20px" />
                      Platform Structure
                    </span>
                  }
                >
                  <Row className="mx-1 pb-3">
                    {buttonMap.map((k, dex) => (
                      <Row key={dex}>
                        <Col>
                          <Button
                            variant="secondary"
                            className={`ps-button w-100 my-2 ${
                              dataIndex - 1 === dex ? "active" : ""
                            }`}
                            onClick={(e) => {
                              setDataIndex(dex + 1);
                            }}
                          >
                            {k[0]}
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                </Tab>
                <Tab
                  eventKey="privileged"
                  title={
                    <span>
                      <FaStar size="20px" />
                      Privileged Positions
                    </span>
                  }
                >
                  <Row className="mx-1 pb-3">
                    {specialButtonMap.map((k, dex) => (
                      <Row key={adjustedIndex(dex)}>
                        <Col>
                          <Button
                            variant="secondary"
                            className={`ps-button w-100 my-2 ${
                              dataIndex === adjustedIndex(dex) ? "active" : ""
                            }`}
                            onClick={(e) => {
                              setDataIndex(adjustedIndex(dex));
                            }}
                          >
                            {k[0]}
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Row>
      </Col>
      <Col xxl={8} md={6} sm={12}>
        <Row className="mt-0">
          <Col className="mx-3 d-flex justify-content-end">
            <Button
              className="buttondownload"
              onClick={(e) => {
                handleDownload();
              }}
            >
              <FaDownload />
              Download
            </Button>
          </Col>
        </Row>
        <div id="graph">
          <Row>
            <Col
              className="h3 bold"
              align="center"
              style={{ fontWeight: "bolder", color: "white" }}
            >
              {index2Header[dataIndex]}
            </Col>
          </Row>
          <Row>
            <Col
              className="h5 font-italic"
              align="center"
              style={{ fontWeight: "bolder", color: "white" }}
            >
              {index2Subheader[dataIndex]}
            </Col>
          </Row>
          <Row style={{ height: "610px" }}>
            <Col>{getGraph()}</Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}
