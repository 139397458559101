import React from "react";
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { BasicTooltip } from '@nivo/tooltip';

const fullDay = {
  "Sun": "Sunday",
  "Mon": "Monday",
  "Tue": "Tuesday",
  "Wed": "Wednesday",
  "Thu": "Thursday",
  "Fri": "Friday",
  "Sat": "Saturday"
}
const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];


const hmTooltip = (props) => {
  return (
    props.cell.data.day ?
      <BasicTooltip
        id={`${fullDay[props.cell.data.x]} ${props.cell.data.day}`}
        value={`${props.cell.data.y}`}
        color={props.cell.color}
        enableChip
      /> : <></>
  );
};

const cell = (props) => (
  <>
    {props.data.day}
  </ >
)

const calendarHeatmap = ({ data, min, max, scheme, background, fontSize, legendColor, props = {} }) => (
  <ResponsiveHeatMap
    data={data}
    margin={{ top: 40, right: 0, bottom: 70, left: 0 }}
    isInteractive={false}
    forceSquare={false}
    label={cell}
    tooltip={hmTooltip}
    labelTextColor="#FFBF00"
    enableLabels={true}
    axisLeft={null}
    emptyColor="#FF000000"
    borderWidth={1}
    hoverTarget={"rowColumn"}
    theme={{
      "background": background,
      "textColor": "#333333",
      "fontSize": fontSize,
      "fontWeight": "bolder",
      "axis": {
        "domain": {
          "line": {

            "stroke": "#777777",
            "strokeWidth": 1
          }
        },
        "legend": {
          "text": {
            "fontSize": 12,
            "fill": "#333333"
          }
        },
        "ticks": {
          "line": {
            "stroke": "#777777",
            "strokeWidth": 1
          },
          "text": {
            "fontSize": 16,
            "fill": legendColor
          }
        }
      },
      "grid": {
        "line": {
          "stroke": "#dddddd",
          "strokeWidth": 1
        }
      },
      "legends": {
        "title": {
          "text": {
            "fontSize": 11,
            "fill": legendColor
          }
        },
        "text": {
          "fontSize": 11,
          "fill": "#333333"
        },
        "ticks": {
          "line": {},
          "text": {
            "fontSize": 0,
            "fill": legendColor
          }
        }
      },
      "tooltip": {
        "container": {
          "background": "#ffffff",
          "color": "#333333",
          "fontSize": 20
        },
        "basic": {},
        "chip": {},
        "table": {},
        "tableCell": {},
        "tableCellValue": {}
      }
    }}
    axisTop={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: 46
    }}
    axisRight={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendPosition: 'middle',
      legendOffset: 40
    }}
    colors={{
      type: 'sequential',
      scheme: scheme,
      steps: 5,
      minValue: min,
      maxValue: max,
    }}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          '0.9'
        ]
      ]
    }}
    legends={
      [
        {
          anchor: 'bottom',
          translateX: 15,
          translateY: 40,
          length: 200,
          thickness: 20,
          direction: 'row',
          tickPosition: 'after',
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: '>-.2s',
          title: 'Pressure →',
          titleAlign: 'start',
          titleOffset: 4
        }
      ]}
    annotations={[]}
    {...props}
  />)

export default function MonthlyHeatmap({
  month = Date.now(),
  data = new Array(31).fill(null).map((d, index) => [index, Math.floor(Math.random() * 600)])
    .reduce((dict, d) => {
      if (Math.random() > 0.07) {
        dict[d[0]] = d[1];
      }
      return dict;
    }, {}),
  idType = "week",
  scheme = 'greens',
  fontSize = "3vmin",
  background = "#ffffff",
  legendColor = "#333333",
  limits = {},
  options = {},
  ...props
}) {

  const template = days.reduce((dict, day) => {
    dict[day] = null;
    return dict
  }, {});
  const date = new Date(month);
  const totalDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  //Offset of the first day of the month in the week e.g Nov 1 => wednesday, offset is 2
  const coff = (d) => d === 0 ? 6 : d - 1;
  const offset = coff(new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`).getDay()-1);
  const _limits = { max: 0, min: Number.MAX_SAFE_INTEGER };

  //Array of week index => day with value if present, otherwise null
  const weeks = new Array(totalDays + 1).fill(null).map((d, index) => index)
    .reduce((dict, day) => {
      const week = Math.floor((day + offset) / 7);
      const name = days[(day + offset) % 7];
      dict[week] = dict[week] ?? { ...template };
      if (data[day] !== undefined) {
        _limits.max = Math.max(_limits.max, data[day]);
        _limits.min = Math.min(_limits.min, data[day]);
        dict[week][name] = { y: data[day], day: day };
      }
      return dict;
    }, {})

  const idGen = {
    week: (index) => `week ${index}`,
    avg: (index, data) => `w/${index} avg: ${Math.round(data.reduce((a, b) => a + b.y ?? 0, 0)
      /
      Math.max(1, data.filter(k => k.y !== null).length))}`
  }

  const graph = calendarHeatmap({
    data: Object.keys(weeks)
      .map(weekIndex => days.map(day => {
        return {
          x: day,
          y: weeks[weekIndex][day]?.y ?? null,
          day: weeks[weekIndex][day]?.day ?? null
        }
      }))
      .map((j, index) => {

        return {
          id: idGen[idType](index + 1, j),
          data: j
        }
      }),
    ..._limits,
    ...limits,
    scheme,
    background,
    fontSize,
    legendColor,
    props: options
  });

  return (
    <div {...props} >
      {graph}
    </div>
  )


}