import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ReleaseInfo from "./components/release-info/ReleaseInfo";
import Compare from "./components/compare/Compare";
import Header from "./components/header/Header";
import Login from "./components/login/Login";
import { useReleaseContext } from "./contexts/release-context/ReleaseContext";
import { useAppContext } from "./contexts/AppContext";
import ReleasesMonthlyBillboard from "./components/monthly-billboard/ReleasesMonthlyBillboard";
import ImpactMonthlyBillboard from "./components/monthly-billboard/ImpactMonthlyBillboard";
import { useCookies } from 'react-cookie';
import { NotificationContainer } from 'react-notifications';
import "./App.css"

function App() {

  const { ReleaseContextProvider } = useReleaseContext();
  const { AppContextProvider } = useAppContext();
  const [authCookie] = useCookies(['svauth']);

  const isLogin = (authCookie['svauth'] === "b49c177bf4bd35469f29b91b9c5071068e7428cfae2cac756a035dc48dd2f6c138b7e58e4272a85eeb6585dab1846cede4c4269d478ab09d47aa2d3fe9f036fa" +
    "64ecceb4ee8f8dd2198afbf94ed1e91374d78e118fada27f4e73389840d127907689854eabdb378a55edb7f529fdb3f4ac3879a58e037cd5b2ef61230643b968") 
    || (authCookie['svauth'] === "9c70c9847965e3c11d09a4162d450e531df3f516a79d92e2e9fddcdef16f75b2fd953a1b2717284947e918cbe21955f3781ce4d689e572f0c7149e523d4e96ae" + 
    "d33a61f235db083546d463ecf2bdd2890d26dd77f36945b6b281579a651f5d307628ea3bdedbc910f17934245ee246faca79843f940033318e37b4a960a1fae5");

  if (!isLogin) {
    return <>
      <NotificationContainer />
      <Login />;
    </>
  }


  return (
    <Router>
      <NotificationContainer />
      <Header />
      <Row className="main-container" >
        <Col md={12}  >
          <AppContextProvider>
            <ReleaseContextProvider>
              <Routes>
                <Route path="/release" element={<ReleaseInfo />} />
                <Route path="/compare" element={<Compare />} />
                <Route path="/amcplus" element={<ImpactMonthlyBillboard />} />
                <Route path="*" element={<ReleasesMonthlyBillboard />} />
              </Routes>
            </ReleaseContextProvider>
          </AppContextProvider>
        </Col>
      </Row>

    </Router>
  );
}

export default App;
