import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DayCard from "./DayCard";
import ChapterHistory from "./ChapterHistory";
import SourceFilter from "../filter/SourceFilter";
import DateNav from "./DateNav";

import "./MontlyBillboard.css";

import { ReleaseContext } from "../../contexts/release-context/ReleaseContext";
import { AppContext } from "../../contexts/AppContext";

//import { getReleasesLastExecution } from "../../services/releases";
//import { useState } from "react";


export default function ReleasesMonthlyBillboard({
  src = "Calendar",
  ...props
}) {
  const { date, setDate, sourceFilter, setSourceFilter } =
    useContext(AppContext);

  const navigate = useNavigate();
  const { getReleases, getReleasesLastExecution } = useContext(ReleaseContext);

  const calendar = getReleases(date);
  const releasesLastExecution = getReleasesLastExecution();
 
 /*const [rle, setRle] = useState('()');
  getReleasesLastExecution().then(d=>{
  	setRle((rle) => rle = d[0]['releases_last_execution']);
  });*/



  const totalDays = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  const weekDayOffset = new Date(
    `${date.getFullYear()}-${date.getMonth() + 1}-01`
  ).getDay();

  const offset = weekDayOffset ? weekDayOffset - 1 : 6;

  const placement = (index) => {
    switch ((index + offset) % 7) {
      case 0:
      case 1:
      case 2:
        return "top-start";
      default:
        return "top-end";
    }
  };

  return (
    <Row className="billboard" {...props}>
      <div className="title-section mb-4">
        <Col className="col-md-12">
          <Row>
            <Col className="text-center">
              <h2 className="h3calendar">Main premieres privileged positions</h2><br/>{'(last executed: '+releasesLastExecution+')'}
            </Col>
          </Row>
        </Col>
      </div>
      <Row className="mx-3">
        <Row className="head-month-info justify-content-between">
          <Col className="order-2 order-md-1" xs={"auto"}>
            <DateNav date={date} onChange={(date) => setDate(date)} />
          </Col>
          <Col className={`order1 order-md-2 `} xs={"auto"}>
            <Row
              className="justify-content-start"
              style={{ width: "auto", maxWidth: "400px" }}
            >
              <Col xs={"auto"}>
                <SourceFilter
                  filter={sourceFilter}
                  onChange={(f) => setSourceFilter(f)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="month-days mx-4 p-5">
          {[
            "MONDAY",
            "TUESDAY",
            "WEDNESDAY",
            "THURSDAY",
            "FRIDAY",
            "SATURDAY",
            "SUNDAY",
          ].map((d) => (
            <div key={d} className="weekday">
              <div className="align-items-center rowdays">{d}</div>
            </div>
          ))}
          {new Array(totalDays).fill(null).map((d, index) => ( 
            <DayCard
              key={index}
              style={{ gridColumnStart: index === 0 ? offset + 1 : "auto" }}
              day={index + 1}
              image={(calendar[index + 1] ?? [])
                .map((d) =>
                  sourceFilter[d.platform]
                    ? {
                        platform: d.platform,
                        title: d.title,
                        poster: d.poster,
                        callbacks: {
                          onClick: () => {
                            navigate(
                              `/release?id=${
                                d.id
                              }&date=${d.date.getTime()}&impact=0&platform=${
                                d.platform
                              }`
                            );
                          },
                        },
                        popover: {
                          side: placement(index),
                          popover: () => <ChapterHistory release={d} />,
                        },
                        payment: d.payment === "1",
                        temp_change: d.temporada_estreno !== '' && d.temporada_estreno !== 'Temporada 1',
                      }
                    : null
                )
                .filter((i) => i)}
            />
          ))}
        </Row>
      </Row>
    </Row>
  );
}
