import { ResponsivePie } from "@nivo/pie";

export const CompareResponsivePieChart = ({ data, platform }) => (
  <>
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 100, bottom: 80, left: 100 }}
      innerRadius={0}
      padAngle={1}
      valueFormat=" >-4.2f"
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      colors={(b) => {
        return {
          [`${platform} series`]: "#ff000044",
          [`${platform} movies`]: "#ff000044",
          [`${platform} other`]: "#ff000044",
        }[b.id];
      }}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      theme={{
        background: "#ffffff00",
        textColor: "#ffffff",
        fontSize: 12,
        tooltip: {
          container: {
            background: "#ffffff",
            color: "#333333",
            fontSize: 12,
          },
        },
      }}
      arcLabelsTextColor="#ffffff"
      arcLinkLabelsThickness={4}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLinkLabelsSkipAngle={0}
      arcLinkLabelsDiagonalLength={8}
      arcLinkLabelsStraightLength={4}
      legends={[]}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: `${platform} other`,
          },
          id: "dots",
        },
        {
          match: {
            id: `${platform} movies`,
          },
          id: "lines",
        },
      ]}
    />
  </>
);
