import React from "react";
import WeekPicker from "./WeekPicker";
import Accordion from "react-bootstrap/Accordion";
import "./WeekPicker.css";

export default function YearlyWeekPicker({
  start = new Date(),
  accordionProps = {},
  ...props
}) {
  const [year, setYear] = React.useState(new Date(start));
  return (
    <>
      <input
        onChange={(e) => {
          setYear(new Date(e.currentTarget.value));
        }}
        style={{ width: "100%" }}
        type="number"
        min="2000"
        max="2099"
        step="1"
        value={year.getFullYear()}
      />
      <Accordion defaultActiveKey="0" {...accordionProps}>
        <Accordion.Item style={{ width: "100%" }} eventKey="0">
          <Accordion.Header bsPrefix="week-accordion-head">
            Weeks
          </Accordion.Header>
          <Accordion.Body>
            <WeekPicker year={year} {...props} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
