import React from "react";
import { Row, Popover, Col } from "react-bootstrap";
import "./MontlyBillboard.css";
import { OverlayTrigger } from "react-bootstrap";

function imgBody(i) { console.log(i);
const mystyle = {
	position:'absolute',
	zIndex: 1,
	fontSize:'12px',
	//background: '#AA0000',
	top: '0px',
	padding:'5px',
	wordBreak:'break-all',
	lineHeight: '13px'
	//letterSpacing:'-0.5px'
};
const mystyle2 = {
	position:'relative',
	zIndex: 0,
	background: '#000000',
	height:'97px',
	//width:'66px',
	boxShadow: '1px 5px 5px black',
	cursor:'pointer'
};
  return (
    <Col
      key={i.title}
      md={4}
      xs={12}
      className={`pt-3 p-0 `}
      style={{ position: "relative" }}
    >
      <div
        className={`img-calendar-box daycard-container platform-${i.platform}`}
      >
        {i.special ? (
          <img
            src="/star.png"
            alt="Carousel"
            title="Carousel"
            style={{
              position: "absolute",
              cursor: "help",
              top: "20px",
              left: "5px",
              width: "20px",
              height: "20px",
              zIndex: 2,
            }}
          />
        ) : (
          <></>
        )}
        {i.payment ? (
          <img
            src="/euro.png"
            alt="Rent"
            title="Rent"
            style={{
              position: "absolute",
              cursor: "help",
              top: "3px",
              left: "6px",
              width: "20px",
              height: "20px",
              zIndex: 2,
            }}
          />
        ) : (
          <></>
        )}
        {i.temp_change ? (
          <img
            src="/change.png"
            alt="Change of Season"
            title="Change of Season"
            style={{
              position: "absolute",
              cursor: "help",
              top: "3px",
              left: "46px",
              width: "20px",
              height: "20px",
              zIndex: 2,
            }}
          />
        ) : (
          <></>
        )}
        {i.poster === 'http://linnea.com.ar/wp-content/uploads/2018/09/404PosterNotFound.jpg' ? <div style={mystyle2} {...(i.callbacks ? i.callbacks : {})}><div style={mystyle}>{(i.title.length > 54) ? i.title.substring(0, 54) + ' ...' : i.title}</div></div> :
          <img
            style={{ cursor: "pointer" }}
            className="imgcalendar"
            alt="no poster"
            //src={`${i.poster.replace('http://svodtracker.com:3000/', 'https://svodtracker.com/images/')}?q=${Date.now()}`}
            src={`${i.poster.replace('http://svodtracker.com:3000/', 'https://svodtracker.com/images/')}`+((i.poster.indexOf('?')>=0)?'&':'?')+`q=${Date.now()}`}
            {...(i.callbacks ? i.callbacks : {})}
          />
        }
      </div>
    </Col>
  );
}

export default function DayCard({ day, name, image = {}, ...props }) {
  return (
    <Row className="day-container" {...props}>
      {image.map((i) =>
        i.popover ? (
          <OverlayTrigger
            key={i.title + Math.random()}
            placement={i.popover.side}
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Popover className="popover-calendar-episodes" {...props}>
                <Popover.Body className="episodes-view-pop">
                  {i.popover.popover()}
                </Popover.Body>
              </Popover>
            )}
          >
            {imgBody(i)}
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            key={i.title + Math.random()}
            placement={"top"}
            delay={{ show: 100, hide: 100 }}
            overlay={(props) => (
              <Popover {...props}>
                <Popover.Body><center><b>{i.title}</b><br />{i.platform} ({i.channel})<br /><font color='red'>{i.section}</font></center></Popover.Body>
              </Popover>
            )}
          >
            {imgBody(i)}
          </OverlayTrigger>
        )
      )}

      <div className="daycard-day">
        <div>{day}</div>
      </div>
    </Row>
  );
}
