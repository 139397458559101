import { ResponsiveBar } from "@nivo/bar";
import { BasicTooltip } from "@nivo/tooltip";
import { useTheme } from "@nivo/core";
import { useMemo } from "react";

const hmTooltip = (separator) => (props) => {
  return (
    <BasicTooltip
      id={`${props.indexValue} - ${props.id}`}
      value={`${props.formattedValue}${separator}`}
      color={props.color}
      enableChip
    />
  );
};

function Labels({ bars }) {
  const theme = useTheme();
  const barsWithValue = useMemo(
    () => bars.filter((bar) => bar.data.value > 0),
    [bars]
  );

  return (
    <>
      {barsWithValue.map(({ color, key, data, height, width, x, y }) => (
        <text
          key={key}
          x={x + width / 2}
          y={y - 8}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            ...theme.labels.text,
            pointerEvents: "none",
            fill: "#dddddd",
            fontSize: 13,
            fontWeight: "bold",
            backgroundColor: "#000000",
          }}
        >
          {data.formattedValue}
        </text>
      ))}
    </>
  );
}

export const CompareResponsiveBarChart = (
  { data, platforms },
  key,
  props = {},
  separator = ""
) => {
  return (
    <ResponsiveBar
      data={data}
      indexBy={key}
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      groupMode="grouped"
      keys={platforms}
      tooltip={hmTooltip(separator)}
      enableLabel={false}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      padding={0.4}
      innerPadding={0}
      colorBy="id"
      colors={(b) => {
        return {
          amazon: "#48a3c6",
          netflix: "#e50914",
          disney: "#E6F72C",
          euskaltel: "#e978d8",
          hbo: "#4826c2",
          movistar: "#66cd00",
          orange: "#fd8204",
          vodafone: "#ffffff",
          amc: "#fa7864",
          other_brands: "#6489fa",
        }[b.id];
      }}
      layers={["grid", "axes", "bars", Labels, "markers", "legends"]}
      theme={{
        background: "#ffffff00",
        textColor: "#ffffff",
        fontSize: 11,
        axis: {
          domain: {
            line: {
              stroke: "#ffffff",
              strokeWidth: 1,
            },
          },
          legend: {
            text: {
              fontSize: 12,
              fill: "#ffffff",
            },
          },
          ticks: {
            line: {
              stroke: "#ffffff",
              strokeWidth: 1,
            },
            text: {
              fontSize: 11,
              fill: "#ffffff",
            },
          },
        },
        grid: {
          line: {
            stroke: "#ffffff",
            strokeWidth: 1,
          },
        },
        legends: {
          title: {
            text: {
              fontSize: 11,
              fill: "#ffffff",
            },
          },
          text: {
            fontSize: 11,
            fill: "#ffffff",
          },
          ticks: {
            line: {},
            text: {
              fontSize: 10,
              fill: "#ffffff",
            },
          },
        },
        annotations: {
          text: {
            fontSize: 13,
            fill: "#ffffff",
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
          },
          link: {
            stroke: "#ffffff",
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
          },
          outline: {
            stroke: "#ffffff",
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
          },
          symbol: {
            fill: "#000000",
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
          },
        },
        tooltip: {
          container: {
            background: "#ffffff",
            color: "#333333",
            fontSize: 12,
          },
          basic: {},
          chip: {},
          table: {},
          tableCell: {},
          tableCellValue: {},
        },
      }}
      axisTop={{
        renderTick: (d) => {
          return `test`;
        },
      }}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: (v) => `${v}${separator}`,
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 50,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
      {...props}
    />
  );
};
