import { ResponsiveLine } from "@nivo/line";
import { BasicTooltip } from "@nivo/tooltip";

const hmTooltip = (props) => {
  return (
    <BasicTooltip
      id={`${props.point.serieId}${props.point.data.x}`}
      value={`${props.point.data.y}`}
      color={props.point.serieColor}
      enableChip
    />
  );
};

export const CompareResponsiveLineChart = ({ data, platforms }) => {
  return (
    <ResponsiveLine
      data={data}
      colors={(b) => {
        return {
          amazon: "#48a3c6",
          netflix: "#e50914",
          disney: "#E6F72C",
          euskaltel: "#e978d8",
          hbo: "#4826c2",
          movistar: "#66cd00",
          orange: "#fd8204",
          vodafone: "#ffffff",
          amc: "#fa7864",
        }[b.id];
      }}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      tooltip={hmTooltip}
      enablePointLabel={true}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "0",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      theme={{
        background: "#ffffff00",
        textColor: "#ffffff",
        fontSize: 13,
        tooltip: {
          container: {
            background: "#ffffff",
            color: "#333333",
            fontSize: 12,
          },
        },
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 50,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 180,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};
