
export const channels=[
  {
    id:1,
    name:"AMC+"
  },
  {
    id:2,
    name:"AMC"
  },
  {
    id:3,
    name:"Hollywood"
  },
  {
    id:4,
    name:"Sundance"
  },
  {
    id:5,
    name:"XTRM"
  },
  {
    id:6,
    name:"Dark"
  },
  {
    id:7,
    name:"SOMOS"
  },
  {
    id:8,
    name:"Odisea"
  },
  {
    id:9,
    name:"Decasa"
  },
  {
    id:10,
    name:"Cocina"
  },
  {
    id:11,
    name:"Panda"
  },
  {
    id:12,
    name:"Sol"
  },
  {
    id:13,
    name:"Historia"
  },
  {
    id:14,
    name:"AMC Break"
  },
  {
    id:15,
    name:"AMC Crime"
  }
  ,
  {
    id:16,
    name:"AMC Selekt"
  },
  {
    id:17,
    name:"Enfamilia"
  },
  {
    id:18,
    name:"BUENVIAJE"
  },
  {
    id:19,
    name:"Historia y Actualidad"
  },
  {
    id:20,
    name:"El Gourmet"
  },
  {
    id:21,
    name:"Planet Horror"
  },
  {
    id:22,
    name:"PROMO AMC"
  },
  {
    id:23,
    name:"Real Crime"
  },
  {
  	id:0,
  	name:"Todos"
  }  
];

export const channelMap=channels.reduce((dict,channel)=>{
  dict[channel.name]=channel;
  return dict;
},{});

export const channelIdMap=channels.reduce((dict,channel)=>{
  dict[channel.id]=channel;
  return dict;
},{});