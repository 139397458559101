import { Row, Col } from 'react-bootstrap';
import { platforms as platformUtil } from '../../utilities/platforms';

import './Filter.css';

export default function SourceFilter({
  platforms = platformUtil.reduce((d, p) => {
    d[p.name] = true;
    return d;
  }, {}),
  filter = {},
  onChange = () => {},
  ...props
}) {
  return (
    <Row
      className='filter-source-container justify-content-start gx-0 px-0'
      {...props}
    >
      {platformUtil.map((p) => (
        <Col
          xs={3}
          key={p.name}
          className={`${platforms[p.name] ? '' : 'd-none'}`}
        >
          <div
            onClick={() => onChange({ ...filter, [p.name]: !filter[p.name] })}
            className={`filter-source-control control-${p.name} ${
              filter[p.name] ? 'filtered' : ''
            }`}
          >
            {p.icon ? p.icon() : <div className={`${p.name}-icon`}></div>}
          </div>
        </Col>
      ))}
    </Row>
  );
}
