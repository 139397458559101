import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaPaperPlane,
  FaRegObjectUngroup
} from 'react-icons/fa';
import "../../App.css";
import "./Header.css";

export default function AlertsysNavbar() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Navbar expand="lg" className="navbar-app" sticky="top">

      <Navbar.Brand className="cursor-pointer px-3" onClick={() => { navigate(`/`); }}>
        <Image src="/iconos/SVODTRACKERLOGO.png" style={{ maxHeight: '50px', cursor: 'pointer', marginRight: '40px' }} ></Image>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-light" style={{ borderWidth: "0px" }} />
      <Navbar.Collapse >
        <Nav className="me-auto">
          <Nav.Link
            className={`mx-md-2 ${location.pathname === '/' ? "active" : ""}`}
            onClick={() => { navigate(`/`); }}>
            <FaPaperPlane title="Calendar" size={20} />
            Releases
          </Nav.Link>
          <Nav.Link
            className={`mx-md-2 ${location.pathname === '/amcplus' ? "active" : ""}`}
            onClick={() => { navigate(`/amcplus`); }}>
            <img src="/iconos/amcplus.png" alt="amc+" style={{ height: "24px", marginTop: "-2px" }} title="AMC+" size={20} />
            Impact
          </Nav.Link>
          <Nav.Link
            className={`mx-md-2 ${location.pathname === '/compare' ? "active" : ""}`}
            onClick={() => { navigate(`/compare`); }}>
            <FaRegObjectUngroup title="Compare" size={20} />
            KPI's
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Nav>
        <Image src="/iconos/logoAMC.png" style={{ maxHeight: '80px', float: 'right' }} ></Image>
      </Nav>
    </Navbar>

  );
}