import { SiNetflix, SiVodafone } from "react-icons/si";

export const platforms = [
  {
    id: 1,
    name: "amazon",
    color: "#48a3c6",
  },
  {
    id: 2,
    name: "netflix",
    color: "#e50914",
    icon: SiNetflix,
  },
  {
    id: 3,
    name: "disney",
    color: "#000c7c",
  },
  {
    id: 4,
    name: "euskaltel",
    color: "#e978d8",
  },
  {
    id: 5,
    name: "hbo",
    color: "#4826c2",
  },
  {
    id: 6,
    name: "movistar",
    color: "#66cd00",
  },
  {
    id: 7,
    name: "orange",
    color: "#fd8204",
  },
  {
    id: 8,
    name: "vodafone",
    color: "#white",
    icon: SiVodafone,
  },
];

export const platformsMap = platforms.reduce((dict, platform) => {
  dict[platform.id] = platform;
  return dict;
}, {});
