import { URL } from "./base";

export async function getCalendarData() {
  try {
    const response = await fetch(`${URL}/calendar.php`, {
      type: "GET",
      mode: "cors"
    });

    return await response.json();
  } catch (error) {
    console.log("Failed to retrieve calendar values ", error)
  }
}

export async function getCalendarPoints(title,amc=false,platform) {
  const response = await fetch(
    `${URL}/heatmap.php?title=${encodeURIComponent(title)}&amc=${amc?1:0}&platform=${platform}`,
    {
      mode: "cors"
    })

  return await response.json();
}

export async function getCalendarKPI(kpi) {
  const response = await fetch(
    `${URL}/reports.php?kpi=${kpi}`,
    {
      mode: "cors"
    })

  return await response.json();
}

export async function getCalendarKPIperiod(begin, end, period) {
  const bform = `${begin.getFullYear()}-${begin.getMonth() + 1}-${begin.getDate()}`;
  const eform = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;

  const response = await fetch(
    `${URL}/reports.php?desde=${bform}&hasta=${eform}&period=${period}`,
    {
      mode: "cors"
    })

  return await response.json();
}