import React from "react";
import "./WeekPicker.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const startOffset = {
  0: 24 * 3600 * 1,
  1: 24 * 3600 * 7,
  2: 24 * 3600 * 6,
  3: 24 * 3600 * 5,
  4: 24 * 3600 * 4,
  5: 24 * 3600 * 3,
  6: 24 * 3600 * 2,
};

const startOffsetSun1 = {
  0: 24 * 3600 * 7,
  1: 24 * 3600 * 6,
  2: 24 * 3600 * 5,
  3: 24 * 3600 * 4,
  4: 24 * 3600 * 3,
  5: 24 * 3600 * 2,
  6: 24 * 3600 * 1,
};

function createWeekMap(year, mode) {
  const begin = new Date(year.getFullYear(), 0);
  const beginOffset = begin.getDay();
  const baseUnix =
    begin.getTime() +
    1000 *
      (mode === "monday"
        ? startOffset[beginOffset]
        : startOffsetSun1[beginOffset]);

  const weeks = [
    begin,
    ...new Array(53)
      .fill(baseUnix)
      .map((d, index) => new Date(d + index * 3600 * 24 * 7 * 1000))
      .filter((d) => d.getFullYear() === year.getFullYear()),
  ];
  return weeks;
}

export default function WeekPicker({
  year,
  mode = "monday",
  onChange = () => {},
  ...props
}) {
  const [weeks, setWeeks] = React.useState(() => createWeekMap(year, mode));
  const [selectedWeek, setSelectedWeek] = React.useState(() => {
    const dex = weeks.findIndex((w) => w >= year);
    return dex === -1 ? null : dex;
  });

  const renderTooltip = (index, d, d2) => (props) => {
    return (
      <Tooltip {...props}>
        <div className="weekpicker-tooltip">
          {`${d.toLocaleString("en-US", {
            day: "numeric",
            weekday: "long",
            month: "long",
          })}${
            d2
              ? " - " +
                d2.toLocaleString("en-US", {
                  day: "numeric",
                  weekday: "long",
                  month: "long",
                })
              : "... next year"
          }`}
        </div>
      </Tooltip>
    );
  };

  React.useEffect(
    () => {
      if (weeks[0].getFullYear() !== new Date(year).getFullYear()) {
        setWeeks(createWeekMap(new Date(year), mode));
        setSelectedWeek(null);
      }
    },
    // eslint-disable-next-line
    [year, mode]
  );

  React.useEffect(
    () => {
      if (selectedWeek) {
        onChange(
          selectedWeek < 50 && year.getFullYear() === 2022
            ? weeks[selectedWeek + 2]
            : weeks[selectedWeek + 1]
        );
      }
    },
    // eslint-disable-next-line
    [selectedWeek]
  );

  return (
    <div className="week-pick-grid" {...props}>
      {Array.isArray(weeks) ? (
        weeks.map((d, index, arr) => (
          <div
            key={index}
            onClick={() => {
              setSelectedWeek(index);
            }}
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 150, hide: 100 }}
              overlay={renderTooltip(index, d, arr[index + 1])}
            >
              <div
                className={`weekpicker-cell ${
                  index === selectedWeek ? "active" : ""
                }`}
              >
                {index + 1}
              </div>
            </OverlayTrigger>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}
