import { createContext, useState } from "react";
import { getReleases, getReleasesImpact, getReleasesLastExecution, getImpactLastExecution } from "../../services/releases";
export const ReleaseContext = createContext({
  getReleases: () => []
});

export function useReleaseContext() {

  const [releases, setReleases] = useState({});
  const [releasesLastExecution, setReleasesLastExecution] = useState('');
  const [releasesImpact, setReleasesImpact] = useState({});
  const [impactLastExecution, setImpactLastExecution] = useState('');

  const _getReleases = (date) => {
    const key = date.toLocaleString('default', { month: "numeric", year: "numeric" });
    if (!releases[key]) {
      releases[key] = [];
      getReleases(date.getMonth() + 1, date.getFullYear())
        .then(d => {
          setReleases(r => {
            r[key] = d.reduce((dict, day) => {
              dict[new Date(day.fecha).getDate()] = day.datos.map(entry => ({ ...entry, date: new Date(day.fecha) }))
              return dict;
            }, {});
            return { ...r };
          })
        })
    }

    return releases[key];
  }

  const _getReleasesLastExecution = () => {
    
    if (!releasesLastExecution) {
      getReleasesLastExecution()
        .then(d => {
        	setReleasesLastExecution((releasesLastExecution) => releasesLastExecution = d[0]['releases_last_execution'])
        })
    }

    return releasesLastExecution;
  }

  const _getReleasesImpact = (date) => {
    const key = date.toLocaleString('default', { month: "numeric", year: "numeric" });
    if (!releasesImpact[key]) {
      releasesImpact[key] = [];
      getReleasesImpact(date.getMonth() + 1, date.getFullYear())
        .then(d => {
          setReleasesImpact(r => {
            r[key] = d.reduce((dict, day) => {
              dict[new Date(day.fecha).getDate()] = day.datos.map(entry => ({ ...entry, date: new Date(day.fecha) }))
              return dict;
            }, {});
            return { ...r };
          })
        })
    }
    return releasesImpact[key];
  }

  const _getImpactLastExecution = () => {
    
    if (!impactLastExecution) {
      getImpactLastExecution()
        .then(d => {
        	setImpactLastExecution((impactLastExecution) => impactLastExecution = d[0]['impact_last_execution'])
        })
    }

    return impactLastExecution;
  }

  return {
    ReleaseContextProvider: ({ children }) =>
      <ReleaseContext.Provider value={{ getReleases: _getReleases, getReleasesImpact: _getReleasesImpact, getReleasesLastExecution: _getReleasesLastExecution, getImpactLastExecution: _getImpactLastExecution }}>
        {children}
      </ReleaseContext.Provider>
  };
}