import React, { useState, createContext } from "react";
export const AppContext = createContext({ at: new Date() });

export function useAppContext() {
  const [date, setDate] = useState(new Date());
  const [impactDate, setImpactDate] = useState(new Date());
  const [sourceFilter, setSourceFilter] = useState({
    netflix: true,
    amazon: true,
  });
  const [sourceFilterImpact, setSourceFilterImpact] = useState({
    orange: true,
    amazon: true,
    vodafone: true,
  });
  const [channelFilter, setChannelFilter] = useState({ 1: true });
  const [selectedOption, setSelectedOption] = useState(null);
  return {
    AppContextProvider: ({ children }) => (
      <AppContext.Provider
        value={{
          date,
          setDate,
          impactDate,
          setImpactDate,
          sourceFilter,
          setSourceFilter,
          selectedOption,
          setSelectedOption,
          channelFilter,
          setChannelFilter,
          sourceFilterImpact,
          setSourceFilterImpact,
        }}
      >
        {children}
      </AppContext.Provider>
    ),
  };
}
