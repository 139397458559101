import React from 'react';
import {
  channels as channelsUtil,
  channelMap,
  channelIdMap,
} from '../../utilities/channels';
import ReactSelect from 'react-select';
import './Filter.css';

export default function ChannelFilter({
  channels = channelsUtil.reduce((d, c) => {
    d[c.name] = true;
    return d;
  }, {}),
  filter = {},
  onChange = () => {},
  ...props
}) {

  const defOptions = Object.keys(channels).map((k) => ({
    label: k,
    value: k,
  }));

  return (
    <div {...props} className={`channel-filter ${props.className ?? ''}`}>
      <ReactSelect
        value={Object.keys(filter)
          .map((id) =>
            filter[id]
              ? { value: channelIdMap[id].name, label: channelIdMap[id].name }
              : null
          )
          .filter((v) => v)}
        onChange={(value) =>
          onChange(
            value.reduce((dict, v) => {
            	if (v.label === 'Todos') {
            		for(var x=1;x<Object.keys(channelIdMap).length;x++) {
            			dict[x] = true;
            		}
            	} else {
              		dict[`${channelMap[v.label].id}`] = true;
              	}
              return dict;
            }, {})
          )
        }
        className='filter-channels'
        classNamePrefix=''
        isClearable={true}
        isMulti
        placeholder={'Select AMC Channels'}
        isSearchable={true}
        name='Select channels'
        options={defOptions}
      />
    </div>
  );
}
