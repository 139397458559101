import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DayCard from "./DayCard";
import SourceFilter from "../filter/SourceFilter";
import DateNav from "./DateNav";
import ChannelFilter from "../filter/ChannelFilter";
import "./MontlyBillboard.css";

import { ReleaseContext } from "../../contexts/release-context/ReleaseContext";
import { AppContext } from "../../contexts/AppContext";

import { channels } from "../../utilities/channels";

export default function ImpactMonthlyBillboard({ src = "Calendar", ...props }) {
  const {
    impactDate,
    setImpactDate,
    sourceFilterImpact,
    setSourceFilterImpact,
    channelFilter,
    setChannelFilter,
    selectedOption,
    setSelectedOption,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { getReleasesImpact, getImpactLastExecution } = useContext(ReleaseContext);

  const calendar = getReleasesImpact(impactDate);
  const impactLastExecution = getImpactLastExecution();
  const [selectedCalendar, setSelectedCalendar] = useState(calendar);
  const sectionValues = ["Inicio", "Home", "Portada", "Inicio - Todo"];
  const totalDays = new Date(
    impactDate.getFullYear(),
    impactDate.getMonth() + 1,
    0
  ).getDate();

  const weekDayOffset = new Date(
    `${impactDate.getFullYear()}-${impactDate.getMonth() + 1}-01`
  ).getDay();

  const offset = weekDayOffset ? weekDayOffset - 1 : 6;
  const options = [
    { value: "Todas", label: "Todas" },
    { value: "Inicio", label: "Inicio" },
    { value: "Otras", label: "Otras secciones" },
  ];
  function handleChange(selectedOption) {
    setSelectedOption(selectedOption);
  }
  useEffect(() => {
    if (selectedOption && selectedOption.value !== "Todas") {
      const filteredCalendar = {};
      if (selectedOption.value === "Inicio") {
        Object.keys(calendar).forEach((key) => {
          filteredCalendar[key] = calendar[key].filter((item) =>
            sectionValues.includes(item.section)
          );
        });
      } else if (selectedOption.value === "Otras") {
        Object.keys(calendar).forEach((key) => {
          filteredCalendar[key] = calendar[key].filter(
            (item) => !sectionValues.includes(item.section)
          );
        });
      }
      setSelectedCalendar(filteredCalendar);
    } else {
      setSelectedCalendar(calendar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, impactDate, channelFilter, sourceFilterImpact]);

  return (
    <Row className="billboard" {...props}>
      <div className="title-section mb-4">
        <Col className="col-md-12">
          <Row>
            <Col className="text-center">
              <Image src="amc-img.png" className="imagetitlesection" />
              <h2 className="h3calendar">AMC+ privileged positions</h2><br/>{'(last executed: '+impactLastExecution+')'}
            </Col>
          </Row>
        </Col>
      </div>
      <Row className="mx-3">
        <Row className="head-month-info justify-content-between">
          <Col className="order-2 order-md-1" xs={"auto"}>
            <DateNav
              date={impactDate}
              onChange={(date) => setImpactDate(date)}
            />
          </Col>
          <Col className={`order1 order-md-2 filter-block`} xs={"auto"}>
            <Row
              className="justify-content-start"
              style={{ width: "auto", maxWidth: "400px" }}
            >
              <Select
                value={selectedOption}
                onChange={handleChange}
                options={options}
                placeholder="Selecciona"
                className="filter-channels second-filter"
                styles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: "lightgray",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "white" : "black",
                    backgroundColor: state.isSelected ? "blue" : "white",
                    "&:hover": {
                      backgroundColor: state.isSelected ? "blue" : "#f2f2f2",
                      color: state.isSelected ? "white" : "black",
                    },
                  }),
                }}
              />
            </Row>
            <Row
              className="justify-content-start"
              style={{ width: "auto", maxWidth: "400px" }}
            >
              <ChannelFilter
                filter={channelFilter}
                style={{ marginBottom: "5px" }}
                onChange={setChannelFilter}
              />
            </Row>

            <SourceFilter
              filter={sourceFilterImpact}
              onChange={(f) => setSourceFilterImpact(f)}
            />
          </Col>
        </Row>
        <Row className="month-days mx-4 p-5">
          {[
            "MONDAY",
            "TUESDAY",
            "WEDNESDAY",
            "THURSDAY",
            "FRIDAY",
            "SATURDAY",
            "SUNDAY",
          ].map((d) => (
            <div key={d} className="weekday">
              <div className="align-items-center rowdays">{d}</div>
            </div>
          ))}
          {new Array(totalDays).fill(null).map((d, index) => (
            <DayCard
              key={index}
              style={{ gridColumnStart: index === 0 ? offset + 1 : "auto" }}
              day={index + 1}
              image={(selectedCalendar[index + 1] ?? [])
                .filter((v) => channelFilter[v.amc])
                .map((d) =>
                  sourceFilterImpact[d.platform]
                    ? {
                        platform: d.platform,
                        title: d.title,
                        channel: channels[d.amc - 1].name,
                        section: d.section,
                        poster: d.poster,
                        callbacks: {
                          onClick: () => {
                            navigate(
                              `/release?id=${
                                d.id
                              }&date=${d.date.getTime()}&impact=1&platform=${
                                d.platform
                              }`
                            );
                          },
                        },
                        special: d.carrousel === "1",
                      }
                    : null
                )
                .filter((i) => i)}
            />
          ))}
        </Row>
      </Row>
    </Row>
  );
}
