import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { FaRegCalendarAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

export default function DateNav({ date, onChange, ...props }) {

  const prevMonth = () => {
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month === 0)
      return new Date(year - 1, 11);
    return new Date(year, month - 1);
  }

  const nextMonth = () => {
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month === 11)
      return new Date(year + 1, 0);
    return new Date(year, month + 1);
  }

  return (
    <Row {...props} className={`date-nav-monthly ${props.className ?? ''}`}>
      <Col align="center" xs={12}>
        {`${date.toLocaleString('en-US', { month: 'long' })}, ${date.getFullYear()}`}

      </Col>
      <Col xs={5}>
        <Button
          variant={"secondary"}
          className="w-100"
          onClick={() => onChange(prevMonth(date))}
        >
          <FaArrowLeft className="me-2" />{new Date(date.getFullYear(), date.getMonth() - 1).toLocaleString('en-US', { month: 'long' })}
        </Button>
      </Col>
      <Col xs={2} className="position-relative">
        <Button className=" w-100 ps-button" variant={"secondary"} >
          <FaRegCalendarAlt size={20} />
        </Button>
        <Form.Control
          type="date"
          onChange={e => onChange(new Date(e.currentTarget.value))}
          style={{
            opacity: 0,
            zIndex: 0,
            position: "absolute",
            minWidth: "100%",
            minHeight: "100%",
            left: "0",
            top: "0",
            cursor: "pointer"
          }} />

      </Col>
      <Col xs={5}>
        <Button
          variant={"secondary"}
          onClick={() => onChange(new Date(nextMonth(date)))}
          className="w-100"
        >
          {new Date(date.getFullYear(), date.getMonth() + 1).toLocaleString('en-US', { month: 'long' })}
          <FaArrowRight className="ms-2" />
        </Button>
      </Col>

    </Row>
  )
}