import { URL } from "./base";

export async function getReleases(month,year){
  try {
    const response = await fetch(`${URL}/releases.php?month=${month}&year=${year}`, {
      type: "GET",
      mode: "cors"
    });
    return await response.json();
  } catch (error) {
    console.log(`Failed to retrieve release ${month}/${year}`, error)
  }
  return [];
}

export async function getReleasesLastExecution(){
  try {
    const response = await fetch(`${URL}/releases_last_execution.php`, {
      type: "GET",
      mode: "cors"
    });
    return await response.json();
  } catch (error) {
    console.log(`Failed to retrieve release last execution`, error)
  }
  return [];
}

export async function getReleasesImpact(month,year){
  try {
    const response = await fetch(`${URL}/impact.php?month=${month}&year=${year}`, {
      type: "GET",
      mode: "cors"
    });
    return await response.json();
  } catch (error) {
    console.log(`Failed to retrieve release ${month}/${year}`, error)
  }
  return [];
}

export async function getImpactLastExecution(){
  try {
    const response = await fetch(`${URL}/impact_last_execution.php`, {
      type: "GET",
      mode: "cors"
    });
    return await response.json();
  } catch (error) {
    console.log(`Failed to retrieve impact last execution`, error)
  }
  return [];
}