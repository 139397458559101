import React, { useEffect, useState, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import MonthlyHeatmap from "../monthly-heatmap/MonthlyHeatmap";
import { getCalendarPoints } from "../../services/calendar";
import queryString from "query-string";
import { ReleaseContext } from "../../contexts/release-context/ReleaseContext";
import { SiHbo, SiVodafone } from "react-icons/si";

import "./ReleaseInfo.css";

function formatSeason(season) {
  return (season ?? "")
    .replace("temporada", "season")
    .replace("Temporada", "Season");
}

export default function ReleaseInfo({ image, data, ...props }) {
  const [heatmap, setHeatmap] = useState(null);
  const navLocation = useLocation();
  const navigate = useNavigate();
  const {
    id = 0,
    impact = "0",
    date = Date.now().getTime(),
    platform = "",
  } = queryString.parse(navLocation.search);
  const numDate = Number.parseInt(date);
  const pdate = new Date(numDate);
  const { getReleases, getReleasesImpact } = useContext(ReleaseContext);

  const monthRelease =
    impact === "0" ? getReleases(pdate) : getReleasesImpact(pdate);

  const datos = Object.entries(monthRelease)
    .map(([day, releases]) => releases)
    .flat()
    .find((e) => e.id === id && (!platform || e.platform === platform)) ?? {
    title: "no info",
    amc: "0",
    platform: "-",
    previous_seasons: [],
    temporada_estreno: "?",
  };

  const dateHead = `${pdate.toLocaleString("en-US", {
    month: "long",
  })} ${pdate.getDate()}, ${pdate.getFullYear()}`;

  const dates = heatmap
    ? Object.keys(heatmap).reduce((dict, key) => {
        const date = new Date(key);
        const monthKey = `${date.getMonth() + 1}/${date.getFullYear()}`;
        dict[monthKey] = dict[monthKey] ?? {};
        dict[monthKey][date.getDate()] = Number.parseInt(heatmap[key]);
        return dict;
      }, {})
    : {};

  const logo = (platform) => {
    switch (platform) {
      case "amazon":
        return (
          <img
            style={{ maxHeight: "5vh" }}
            src={"/iconos/Amazon_Prime_Video.png"}
            alt="no logo"
          />
        );
      case "netflix":
        return (
          <img
            style={{ maxHeight: "5vh" }}
            src={"/iconos/net.png"}
            alt="no logo"
          />
        );
      case "orange":
        return (
          <img
            style={{ maxHeight: "100px" }}
            src={"/iconos/Orange_UK.svg"}
            alt="no logo"
          />
        );
      case "disney":
        return (
          <img
            style={{ maxHeight: "50px" }}
            src={"/iconos/Disney.svg"}
            alt="no logo"
          />
        );
      case "euskaltel":
        return (
          <img
            style={{ maxHeight: "50px" }}
            src={"/iconos/Euskaltel_logo.svg"}
            alt="no logo"
          />
        );
      case "hbo":
        return <SiHbo style={{ marginTop: "-10px" }} size={60} />;
      case "movistar":
        return (
          <img
            style={{ height: "50px" }}
            src={"/iconos/Movistar_logo.svg"}
            alt="no logo"
          />
        );
      case "vodafone":
        return <SiVodafone size={60} />;
      default:
        return "";
    }
  };

  const matchDates = (d, e) => {
    return d.getMonth() === e.getMonth() && d.getFullYear() === e.getFullYear();
  };

  const parseMonthDate = (d) => {
    return new Date(d.split("/")[1], Number.parseInt(d.split("/")[0]) - 1);
  };

  const navigateTitle = () => {
    if (impact === "0") return "Calendar";
    return "AMC+";
  };

  useEffect(
    () => {
      if (datos.title !== "no info")
        getCalendarPoints(datos.title, impact !== "0", datos.platform).then((d) => {
          setHeatmap(d);
        });
    },
    // eslint-disable-next-line
    [monthRelease]
  );

  return (
    <Row className="mx-4 mt-4" {...props}>
      <Row
        className="justify-content-start h3 "
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <Col md={"auto"}>
          <FaArrowLeft /> {navigateTitle()}
        </Col>
      </Row>
      <Row>
        <Row>
          <Col className="date-head" md={"auto"}>
            {dateHead}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6} sm={6}>
            <img
              className="release-image"
              alt={datos.title}
              src={datos.poster.replace('http://svodtracker.com:3000/','https://svodtracker.com/images/')}
            />
          </Col>
          <Col md={6} sm={6}>
            <Row className="justify-content-start align-items-center">
              <Col className="align-self-center h3" md={"auto"}>
                <strong>{datos.title}</strong>
              </Col>
              <Col md={"auto"}>{logo(datos.platform)}</Col>
            </Row>
            <Row
              className={`${datos.temporada_estreno ? "" : "d-none"} my-3 h4`}
            >
              <Col>
                <strong>{formatSeason(datos.temporada_estreno)}</strong>
              </Col>
            </Row>
            <Row
              className={`my-1 ${
                datos.number_chapters_premiere ? "" : "d-none"
              }`}
            >
              <Col>
                {`Number of episodes in premiere: ${datos.number_chapters_premiere}`}
              </Col>
            </Row>
            <Row className="my-1">
              <Col>{`Number of CLICKS to access: ${datos.clicks}`}</Col>
            </Row>
            <Row className={`${datos.previous_seasons ? "" : "d-none"} my-1`}>
              <Col>Previous Seasons:</Col>
            </Row>
            <Row className={`${datos.previous_seasons ? "" : "d-none"}`}>
              {Object.keys(datos.previous_seasons ?? {}).map((s) => (
                <Row key={datos.previous_seasons[s]} className="my-1">
                  <Col>
                    <div style={{ color: "#AFDFE5" }}>
                      - {formatSeason(datos.previous_seasons[s])}
                    </div>
                  </Col>
                </Row>
              ))}
            </Row>
          </Col>
        </Row>
      </Row>
      <Row className="mt-4 h4">
        <Col md={"auto"}>Heatmap BEFORE and AFTER premier</Col>
      </Row>
      <Row>
        {Object.keys(dates)
          .map((key) => [parseMonthDate(key), key])
          .map(([d, key]) => (
            <Col key={key} xl={4} lg={6} xs={12}>
              <div
                className={`heatmap-box ${
                  matchDates(d, pdate) ? "release-date-box" : ""
                }`}
              >
                <Row className="">
                  <Col className=" my-3" md={"auto"} sm={"auto"}>
                    <Row>
                      <Col md={"auto"} sm={"auto"}>
                        <strong>
                          {`${d
                            .toLocaleString("en-US", { month: "long" })
                            .toUpperCase()}, ${d.getFullYear()}`}
                        </strong>
                      </Col>
                      <Col
                        className="d-flex align-items-end"
                        style={{ color: "cyan" }}
                        md={"auto"}
                        sm={"auto"}
                      >
                        {matchDates(d, new Date()) ? (
                          <div>(Current month)</div>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ margin: "0", paddingRight: "25px" }}>
                  <Col>
                    <MonthlyHeatmap
                      month={d}
                      data={dates[key]}
                      className="heatmap-release"
                      fontSize={"16px"}
                      background="#ffffff00"
                      legendColor="white"
                      idType={"avg"}
                      scheme="orange_red"
                      limits={{ min: 1, max: 5 }}
                      options={{
                        emptyColor: "#00000055",
                        labelTextColor: "#000",
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
      </Row>
    </Row>
  );
}
