import CalendarGrid from "../calendar-grid/CalendarGrid";
import { Row, Col } from 'react-bootstrap';
import "./ChapterHistory.css";


export default function ChapterHistory({
  release,
  ...props
}) {

  const episodes = [
    {
      date: new Date(release.date.toLocaleString('en-Us', { month: "numeric", year: "numeric", day: "numeric" })),
      episodes: release.number_chapters_premiere
    },
    ...Object.keys(release.next_chapters).map(k => ({
      date: new Date(k),
      episodes: release.next_chapters[k]
    
    }))
  ];
  const releaseDate = release.date;

  return (
    <>
      <Row className="chapter-history-title d-flex justify-content-center">
        <Col className="col-auto">
          {release.title}
        </Col>
      </Row>
      <Row className="chapter-history-info d-flex justify-content-center">
        <Col className="col-auto">
          Episode release per week
        </Col>
      </Row>
      <div className="history-month-container">
        {
          Object.entries(episodes.reduce((dict, ep) => {
            const key = `${ep.date.getFullYear()}-${ep.date.getMonth() + 1}`;
            dict[key] = dict[key] ?? {};
            dict[key][ep.date.getDate()] = [false,ep.episodes];
            return dict;
          }, {}))
            .map(([month, days]) => {
              if (month === `${new Date(releaseDate).getFullYear()}-${new Date(releaseDate).getMonth() + 1}`)
                days[releaseDate.getDate()][0] = true;
              return [month, days];
            })
            .map(([month, days]) =>
              <div className="calendarmini" key={month}>
                <div className="d-flex justify-content-center">
                  {`${new Date(month).toLocaleString('en-US', { month: 'long' })}, ${new Date(month).getFullYear()}`}
                </div>
                <CalendarGrid
                  month={month}
                  generator={(d) => {
                    if (days[d])
                      return (
                        <div align="center" style={{ color: "black" }} className={`ep-ball ${days[d][0]? 'start-ep' : ''}`} >
                          {days[d][1]}
                        </div>
                      )
                    return d;
                  }}
                />
              </div>
            )
        }
      </div>
    </>
  )
}