import { useMemo } from "react";

function defaultHead(d) {
  return (
    <div key={d} className="weekday">
      <div className="align-items-center rowdays">{d}</div>
    </div>
  );
}

export default function CalendarGrid({
  month = Date.now(),
  generator = (day) => day,
  classCell = "cgrid-cell",
  head = defaultHead,
  ...props
}) {
  const date = new Date(month);
  const totalDays = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  const weekDayOffset = new Date(
    `${date.getFullYear()}-${date.getMonth() + 1}-01`
  ).getDay();

  const offset = weekDayOffset ? weekDayOffset - 1 : 6;
  const days = useMemo(
    () =>
      new Array(Number.isNaN(totalDays) ? 0 : Math.max(totalDays, 0))
        .fill(null)
        .map((d, index) => index + 1)
        .map((index) => (
          <div
            className={`${classCell} ${classCell}-${index}`}
            key={index}
            style={{ gridColumnStart: index === 1 ? offset + 1 : "auto" }}
          >
            {generator(index)}
          </div>
        )),
    [generator, classCell, totalDays, offset]
  );

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: ["repeat(7,1fr)"],
      }}
      {...props}
    >
      {["MO", "TU", "WE", "TH", "FR", "SA", "SU"].map(head)}
      {days}
    </div>
  );
}
