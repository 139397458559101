import { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { NotificationManager } from 'react-notifications';
import { sha512 } from 'js-sha512';

import "./Login.css";

export default function Login({ ...props }) {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [authCookie, setAuthCookie] = useCookies(['svauth']);

  useEffect(() => {
    if (authCookie['svauth']) {
      NotificationManager.error("Invalid user or password");
    }
  }, [authCookie])

  return (
    <Container className="login-container">
      <Row   >
        <Col className="login-body" xs={10} xl={8}>
          <Row className="justify-content-start">
            <Col className="header-login ms-auto me-auto mb-4" xs={"auto"}>
              <div className="login-logo" style={{ backgroundImage: 'url("/iconos/SVODTRACKERLOGO.png")' }}></div>
            </Col>
            <Col className="mt-4 mb-3" xs={12}>
              Username:
            </Col>
            <Col xs={12} xl={6}>
              <Form.Control placeholder="Username..." onChange={(e) => { setUsername(e.target.value) }} />
            </Col>
            <Col className="mt-4 mb-3" xs={12}>
              Password:
            </Col>
            <Col xs={12} xl={6}>
              <Form.Control placeholder="Password..." onChange={(e) => { setPassword(e.target.value) }} type="password" />
            </Col>
            <Col xs={12}></Col>
            <Col className="mt-5" xs={12} xl={6}>
              <Button
                disabled={!username || !password}
                className="login-button py-3"
                onClick={() => {
                  setAuthCookie('svauth', sha512(username) + sha512(password),
                    { sameSite: "none", expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 1), secure: true });
                }}
              >Login</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}